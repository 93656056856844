import { BrowserRouter, Routes, Route } from "react-router-dom";
// Site pages
import Layout from "./Layout";
import Home from "./Home";
import About from "./About";
import MailingList from "./MailingList"
import Error404 from "./Error404";

import HailToTheChief from "./hail_to_the_chief/HailToTheChief";
import DeveloperLogHTTC from "./hail_to_the_chief/DeveloperLog";
import HTTCPost1 from "./hail_to_the_chief/developer_log_posts/1-Vision"
import HTTCPost2 from "./hail_to_the_chief/developer_log_posts/2-RealTime"


function App() {
  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />}/>
            <Route path="about" element={<About />}/>
            <Route path="mailing-list" element={<MailingList />}/>
            <Route path="hail-to-the-chief" element={<HailToTheChief />}/>
            <Route path="hail-to-the-chief/developer-log" element={<DeveloperLogHTTC />}/>
            <Route path="hail-to-the-chief/developer-log/1-vision" element={<HTTCPost1 />}/>
            <Route path="hail-to-the-chief/developer-log/2-realtime" element={<HTTCPost2 />}/>
            <Route path="*" element={<Error404 />}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default App;
