import { Link } from 'react-router-dom';
import './DeveloperLog.css';

function DeveloperLog() {
  return (
    <main className='DeveloperLog'>
      <h1>HAIL TO THE CHIEF - DEVELOPER LOG</h1>
      <section className='DeveloperLog-Grid'>
        <Link to="./1-vision" className='DeveloperLog-GridItem'>
            <p>#1</p>
            <h1>Vision</h1>
            <p><em>15th March 2024</em></p>
        </Link>
        <Link to="./2-realtime" className='DeveloperLog-GridItem'>
            <p>#2</p>
            <h1>Real Time</h1>
            <p><em>29th March 2025</em></p>
        </Link>
      </section>
    </main>
  );
}

export default DeveloperLog;
