import '../DeveloperLogPost.css';

function Post() {
  return (
    <main className='Post'>
      <h1>Real Time</h1>
      <section className='Post-Date'>
        <em>29th March 2025</em>
      </section>
      <h2>ONE YEAR OF DEVELOPMENT</h2>
      <p>
        It's quite hard to believe it's been over a year since my first, and last, developer log update. In that time so
        much has happened, yet when it comes to development it can feel like so little. I've ticked off countless
        tickets for all manner of features and improvements, but like the heads of a hydra, many more arise in
        replacement.
        <br/><br/>
        I'm not complaining, even though it might sound that way. I am grateful to have so many good ideas for features
        I can implement. Hail to the Chief will be better as a result. That said, this all comes at a cost. The
        principal cost, as is so often the case, is a cost of time. I had hoped that I would be able to release the
        first early access versions of my game late last year, but this proved to be far too ambitious. Perhaps, then,
        this year will be different?
        <br/><br/>
        The truth is, it's hard to say. I'm not a company, I'm just one person. I have a life outside of developing this
        game, I have a full time job and other commitments, and I don't think I appreciated just how much that takes
        out of you in terms of effort and motivation. To finish a day of work and then spend the evening working anew,
        even if that project is something personal, is a tougher ask than it might seem.
        <br/><br/>
        Nevertheless, I keep working. Slowly, but surely, this game continues to take shape. I know that the time
        <em> will</em> come when it is ready to share, and I know that when I get there then all this time will have been
        worth spending.
        <br/><br/>
        Speaking of time, I've made quite a significant change to the game.
      </p>
      <h2>REAL TIME</h2>
      <p>
        I previously described Hail to the Chief as 'round-based', being neither strictly turn-based or real-time. While
        it was a nice idea in theory, I've found that the implementation of various systems is hampered by not having
        any reference point in terms of units of time. How much should a unit move in a round? What does that mean for
        the speed of research? What if it takes as long to build a factory as it does for a unit to march 10 miles? How
        can I incorprate fast paced events when each turn could equate to weeks of time?
        <br/><br/>
        The solution, as it happens, was to create that temporal reference. The most straightforward way to do that was
        to convert the game to a real-time game, rather than a round-based one. It's a decision that I contemplated
        heavily, but I'm certain that it's the right one. That said, it took some time to convert the existing systems
        to work with a real-time approach, and it's introduced new challenges which I will have to overcome.
        <br/><br/>
        Those of who you read the post explaining my vision for the game will know I was wary of real-time approaches,
        as I did not want the game to turn into a sort of clicking competition. This remains the case, and I am going to
        be doing my best to ensure that the time controls available to players allow time for slower players to think
        and respond, but while also ensuring that faster players do not end up losing interest due to constant pauses
        and slowdowns. It's hard to find the right compromise, but I have a few ideas and I'm hoping they will work as
        well in practice as they do in my head.
        <br/><br/>
        All things considered, I think this change will offer a lot more opportunities for gameplay, and will make the
        gameplay experience feel altogether more unified and thematically consistent. All I have to do is make it work!
      </p>
      <h2>IN CONCLUSION</h2>
      <p>
        I recognise that my updates are not always timely or consistent compared to the norm for game development
        journeys, but do ask for your patience. As I explain above, I am ultimately just one person working on this game
        alongside the entire rest of my life. It's not an excuse per se, but I do hope it offers some explanation. Rest
        assured that I remain as committed to Hail to the Chief as ever. You will lead your nation, and it will be
        glorious...I hope!
        <br/><br/>
        Thanks for reading,
        <br/>
        Racctuality
      </p>
    </main>
  );
}

export default Post;